import request from "../../../util/request";
export function getAliOssTokenRequest( callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('utoken', sessionStorage.getItem("utoken"));
    return request("/aliyun/osssts.php", {
        method: "POST",
        body: filedata
    }, callback);
}

export function getXueqiListRequest( callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_xueqi_list");
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}
export function getXSSMSchoolListRequest( xueqi_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_yiwancheng_shuoming_schools");
    filedata.append('xueqi_code',xueqi_code)
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}
// 获取单个学校已提交的学术说明内容
export function getXSSMSchoolInfoRequest( xueqi_code,school_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "get_school_shuoming_info");
    filedata.append('xueqi_code',xueqi_code)
    filedata.append('school_code',school_code)
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}



export function addXSSMSchoolInfoRequest( xueqi_code,xueqi_name,school_code,
                                          school_name,start_time,end_time,
                                          school_canhui,yanjiusuo_canhui,
                                          huiyi_image,jielun_code,jielun_name,
                                          callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "add_school_shuoming");
    filedata.append('xueqi_code',xueqi_code)
    filedata.append('xueqi_name',xueqi_name)
    filedata.append('school_code',school_code)
    filedata.append('school_name',school_name)
    filedata.append('start_time',start_time)
    filedata.append('end_time',end_time)
    filedata.append('school_canhui',school_canhui)
    filedata.append('yanjiusuo_canhui',yanjiusuo_canhui)
    filedata.append('huiyi_image',huiyi_image)
    filedata.append('jielun_code',jielun_code)
    filedata.append('jielun_name',jielun_name)
    return request("/Yunyingd_a/i", {
        method: "POST",
        body: filedata
    }, callback);
}



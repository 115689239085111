// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./SBPSHJ.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch, faReply} from '@fortawesome/free-solid-svg-icons';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message, DatePicker, Upload, Modal} from 'antd'
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from "dayjs";
import 'dayjs/locale/zh-cn'
import {
    setRangeTime,
    selectRangeTime,
    selectpsjlIndex,
    setpsjlIndex,
    selectpsjlList,
    selectSBPSXueqiList,
    selectSBPSXueqiIndex,
    setSBPSXueqiIndex,
    setXSSMSchoolIndex,
    selectXSSMSchoolIndex,
    selectSBPSSchoolList,
    setSBPSXueqiList,
    setXxchr,
    selectXxchr,
    setYjschr,
    setSBPSSchoolList,
    selectYjschr,
    setXSSMSchoolList,
    selectXSSMSchoolList,
    setFileList1,
    setFileList2,
    setFileList3,
    setFileList4,
    selectFileList1,
    selectFileList2,
    selectFileList3,
    selectFileList4, setSBPSDetailInfo,
} from "./SBPSHJSlice";
import {API_URL} from "../../../util/constants";
import {selectDingSchoolList} from "../ZXSchool/ZXSchoolSlice";
import OSS from 'ali-oss'
import {
    addSBPSSchoolInfoRequest,
    getAliOssTokenRequest,
    getSBPSSchoolInfoRequest,
    getSBPSSchoolListRequest, getXSSMSchoolListRequest,
    getXueqiListRequest
} from "./SBPSHJApi";
import {getSixMathRandom} from "../../../util/handMath";
import {setSBPSMenuIndex, setXMLDMenuIndex, setXSSMMenuIndex} from "../ZXYYMain/ZXYYMainSlice";
import {getXSSMSchoolInfoRequest} from "../XSSMHJ/XSSMHJApi";
import {setXSSMDetailInfo} from "../XSSMHJ/XSSMHJSlice";

dayjs.locale('zh-cn')
const {RangePicker} = DatePicker;


let client;
let clientExpTime;


export function SBPSHJ() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})
    const [tjStatus, setTjStatus] = useState(0)

    const SBPSXueqiList = useSelector(selectSBPSXueqiList)
    const SBPSXueqiIndex = useSelector(selectSBPSXueqiIndex)
    const XSSMSchoolIndex = useSelector(selectXSSMSchoolIndex)
    const SSMSchoolList = useSelector(selectXSSMSchoolList)
    const SBPSSchoolList = useSelector(selectSBPSSchoolList)
    const fileList1 = useSelector(selectFileList1)
    const fileList2 = useSelector(selectFileList2)
    const fileList3 = useSelector(selectFileList3)
    const fileList4 = useSelector(selectFileList4)
    const [previewVisible, setPreviewVisible] = useState(false);
    const [previewImage, setPreviewImage] = useState("");
    const rangeTime = useSelector(selectRangeTime)
    const xxchr = useSelector(selectXxchr)
    const yjschr = useSelector(selectYjschr)

    const psjlIndex = useSelector(selectpsjlIndex)
    const psjlList = useSelector(selectpsjlList)
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        getAliOssToken()
        getXueqiList()
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次
    function getXueqiList(index) {
        getXueqiListRequest((data) => {
            if (data.ret == 1) {
                if(data.data) {
                    dispatch(setSBPSXueqiList(data.data));
                    data.data.map((item, index) => {
                        if (item.dangqian_xueqi == 1) {
                            dispatch(setSBPSXueqiIndex(index))
                            getSBPSSchoolList(item.xueqi_code, 0)
                            getXSSMSchoolList(item.xueqi_code, 0)
                        }
                    })
                }
            } else {
                messageApi.open({
                    type: 'error',
                    content: "学期请求失败，请重新进入该界面"
                })
            }
        })
    }

    function getSBPSSchoolList(xueqiCode, index) {
        getSBPSSchoolListRequest(xueqiCode, (data) => {
            if (data.ret == 1) {
                dispatch(setSBPSSchoolList(data.data));
            } else {
            }
        })
    }


    function getXSSMSchoolList(xueqiCode, index) {
        getXSSMSchoolListRequest(xueqiCode, (data) => {
            if (data.ret == 1) {
                dispatch(setXSSMSchoolList(data.data));
                dispatch(setXSSMSchoolIndex(index))
                if(data.data&&data.data.length>0){
                    getSBPSSchoolInfo(xueqiCode, data.data[0].school_code)
                }
            } else {

            }
        })
    }

    function getSBPSSchoolInfo(xueqi_code, school_code) {
        getSBPSSchoolInfoRequest(xueqi_code, school_code, (data) => {
            if(data.ret == 1){
                // set
                let result = data.data;
                dispatch(setRangeTime([result.start_time,result.end_time]))
                dispatch(setXxchr(result.school_canhui))
                dispatch(setYjschr(result.yanjiusuo_canhui))
                let tempList1 = result.huiyi_image.split(',')
                tempList1 = tempList1.map((item, index) => {
                    let tempDic = {
                        "url":item,
                        "uid":index
                    }
                    return tempDic
                })
                dispatch(setFileList1(tempList1))

                let tempList2 = result.shenqingbiao_image.split(',')
                tempList2 = tempList2.map((item, index) => {
                    let tempDic = {
                        "url":item,
                        "uid":index
                    }
                    return tempDic
                })
                dispatch(setFileList2(tempList2))

                let tempList3 = result.cailiao__url.split(',')
                tempList3 = tempList3.map((item, index) => {
                    let tempDic = {
                        "url":item,
                        "uid":index
                    }
                    return tempDic
                })
                dispatch(setFileList3(tempList3))

                let tempList4 = result.xieyi_image.split(',')
                tempList4 = tempList4.map((item, index) => {
                    let tempDic = {
                        "url":item,
                        "uid":index
                    }
                    return tempDic
                })
                dispatch(setFileList4(tempList4))


                dispatch(setpsjlIndex(result.jielun_code - 1))
                setTjStatus(1)
            }
            else {
                dispatch(setRangeTime(["",""]))
                dispatch(setXxchr(""))
                dispatch(setYjschr(""))
                dispatch(setFileList1([]))
                dispatch(setFileList2([]))
                dispatch(setFileList3([]))
                dispatch(setFileList4([]))
                dispatch(setpsjlIndex(0))
                setTjStatus(0)
            }
        })
    }

    function getSchoolList(index) {
        // getSchoolListRequest(xuezhiList[index].xuezhi_code,(data)=>{
        //
        // })
    }

    function getAliOssToken() {
        let nowDate = new Date().getTime()
        if (!client||nowDate - clientExpTime>1200000) {
            clientExpTime = new Date().getTime();
            getAliOssTokenRequest((data) => {
                if ("Credentials" in data) {
                    client = new OSS({
                        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                        region: 'oss-cn-qingdao',
                        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                        accessKeyId: data.Credentials.AccessKeyId,
                        accessKeySecret: data.Credentials.AccessKeySecret,
                        // 从STS服务获取的安全令牌（SecurityToken）。
                        stsToken: data.Credentials.SecurityToken,
                        refreshSTSToken: async () => {
                            // 向您搭建的STS服务获取临时访问凭证。
                            getAliOssTokenRequest((data) => {
                                return {
                                    accessKeyId: data.Credentials.AccessKeyId,
                                    accessKeySecret: data.Credentials.AccessKeySecret,
                                    stsToken: data.Credentials.SecurityToken,
                                }
                            })

                        },
                        // 刷新临时访问凭证的时间间隔，单位为毫秒。
                        refreshSTSTokenInterval: 3000000,
                        // 填写Bucket名称。
                        bucket: 'tgnp'
                    })
                }
            })
        }
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {

        }

    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

    function handleCancel() {
        setPreviewVisible(false);
    }

    function isImage(filename) {
        const pattern = /\.(jpg|jpeg|png|gif|svg|bmp)$/i;
        return pattern.test(filename);
    }

    async function handlePreview(file) {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        if(isImage(file.url)) {
            setPreviewImage(file.url || file.preview)
            setPreviewVisible(true);
        }
        else {
            if(!file.url) {
                message.open({
                    type: "warning",
                    content: "链接无效"
                })
            }
            window.location.href = file.url
        }
    }
    async function handlePreviewWork(file) {
        if(!file.url) {
            message.open({
                type: "warning",
                content: "链接无效"
            })
        }
        window.location.href = file.url
    }

    function handleChange1(info){
        getAliOssToken()
        let tempList = info.fileList.map((item, index) => {
            let tempDic = {
                "url":item.url,
                "uid":item.uid,
                "status":item.status
            }
            return tempDic
        })
        dispatch(setFileList1(tempList));
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }
    function handleChange2(info){
        getAliOssToken()
        let tempList = info.fileList.map((item, index) => {
            let tempDic = {
                "url":item.url,
                "uid":item.uid,
                "status":item.status
            }
            return tempDic
        })
        dispatch(setFileList2(tempList));
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }
    function handleChange3(info){
        getAliOssToken()
        let tempList = info.fileList.map((item, index) => {
            let tempDic = {
                "url":item.url,
                "uid":item.uid,
                "status":item.status
            }
            return tempDic
        })
        dispatch(setFileList3(tempList));
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }
    function handleChange4(info){
        getAliOssToken()
        let tempList = info.fileList.map((item, index) => {
            let tempDic = {
                "url":item.url,
                "uid":item.uid,
                "status":item.status
            }
            return tempDic
        })
        dispatch(setFileList4(tempList));
        if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
        }
        if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
        }
    }


    const customRequest = async options => {
        const {onSuccess, onError, file} = options;

        try {
            // 使用阿里云OSS SDK上传文件
            let timestamp = new Date().getTime()
            let random = getSixMathRandom()
            const result = await client.put("shenbao/" + timestamp + random + file.name, file);
            file.url = result.url;
            onSuccess(file);
        } catch (e) {
            onError(e);
        }
    };

    const dateFormat = 'YYYY-MM-DD HH:mm';
    var currentDate = new Date();
    // var maxDateString = currentDate.toJSON().slice(0, 10);
    var maxDateString = (parseInt(currentDate.toJSON().slice(0, 4))+5)+"-12-31 00:00";
    const uploadButton = (
        <div>
            {/*<Icon type="plus" />*/}
            <div className="ant-upload-text">上传</div>
        </div>
    );
    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            <div className={styles.topBar}>
                <div className={styles.topBarLine1}>
                    <span className={styles.xueqiLabel}>学期：</span>
                    <select className={styles.xueqiData}
                            onChange={(e) => {
                                dispatch(setSBPSXueqiIndex(e.target.value))
                                getSBPSSchoolList(SBPSXueqiList[e.target.value].xueqi_code, 0)
                                getXSSMSchoolList(SBPSXueqiList[e.target.value].xueqi_code, 0)
                            }}
                            value={SBPSXueqiIndex}
                    >
                        {SBPSXueqiList.map((item, index) => {
                            return <option value={index}>{item.xueqi_nmae}</option>
                        })}
                    </select>
                </div>
                <div className={styles.topBarLine2}>
                    <span className={styles.xueqiLabel}>已完成“申报评审会”的学校：</span>
                    <div className={styles.YWCSchoolContainer}>
                        {SBPSSchoolList.length > 0 ? SBPSSchoolList.map((item, index) => {
                            // return <span className={styles.schooolName}>{item.school_name}</span>
                            return <span>
                                <span className={styles.schooolName}>{item.school_name}</span>
                                <span className={styles.lookDetail}
                                      onClick={(e)=>{
                                          getSBPSSchoolInfoRequest(
                                              SBPSXueqiList[SBPSXueqiIndex].xueqi_code,
                                              item.school_code,
                                              (data)=>{
                                                  if(data.ret == 1){
                                                      dispatch(setSBPSDetailInfo(data.data))
                                                      dispatch(setSBPSMenuIndex(1))
                                                  }
                                              }
                                          )
                                          // dispatch(setXSSMMenuIndex(1))
                                      }}
                                >查看></span>
                            </span>
                        }) : <span className={styles.schooolName}>暂无</span>}
                    </div>
                </div>
            </div>

            <div className={styles.content}>
                <div className={styles.contentLine1}>
                    <span
                        className={styles.contentTitle}>跟已开过“学术说明会”的学校进一步沟通，推动召开“申报评审会”，并记录：</span>
                </div>
                <div className={styles.contentBottom}>
                    <div className={styles.bottomLine1}>
                        <span className={styles.bottomLeftTitle}>
                            学校：
                        </span>
                        {SSMSchoolList.length>0?<select className={styles.schoolList}
                                onChange={(e) => {
                                    dispatch(setXSSMSchoolIndex(e.target.value))
                                    getSBPSSchoolInfo(SBPSXueqiList[SBPSXueqiIndex].xueqi_code, SSMSchoolList[e.target.value].school_code)
                                }}
                                value={XSSMSchoolIndex}
                        >
                            {SSMSchoolList.map((item, index) => {
                                return <option value={index}>{item.school_name}</option>
                            })}
                        </select>:<div>
                        <span className={styles.zanwu}>暂无</span>
                        <span className={styles.goZXCD}
                              onClick={(e)=>{
                                  dispatch(setXMLDMenuIndex(1))
                              }}
                        >需先完成“学术说明环节”></span>
                    </div>}
                    </div>
                    <div className={styles.bottomLine1}>
                        <span className={styles.bottomLeftTitle}>
                            会议日期：
                        </span>
                        {tjStatus==0?<RangePicker
                            locale={locale}
                            // needConfirm = {true}
                            // minDate={dayjs('2024-01-01', dateFormat)}
                            minDate={dayjs('2024-01-01 00:00', dateFormat)}
                            maxDate={dayjs(maxDateString, dateFormat)}
                            showTime={{format: 'HH:mm'}}
                            value={rangeTime[0].length > 0 ? [dayjs(rangeTime[0], dateFormat), dayjs(rangeTime[1], dateFormat)] : null}
                            onChange={(date, dateString) => {
                                dispatch(setRangeTime(dateString))
                            }}
                        />
                            :<span className={styles.rightContent1}>{rangeTime[0]}&nbsp;~&nbsp;{rangeTime[1]}</span>
                        }
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            学校参会人：
                        </span>
                        {tjStatus==0?<textarea
                            className={styles.textarea}
                            // value={beizhu}
                            onChange={(e) => {
                                dispatch(setXxchr(e.target.value))
                            }}
                            placeholder='请输入~'
                            value={xxchr}
                        ></textarea>:
                            <div className={styles.textarea}>{xxchr}</div>}
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            研究所参会人：
                        </span>
                        {tjStatus==0?<textarea
                            className={styles.textarea}
                            // value={beizhu}
                            onChange={(e) => {
                                dispatch(setYjschr(e.target.value))
                            }}
                            placeholder='请输入~'
                            value={yjschr}
                        ></textarea>:
                            <div className={styles.textarea}>{yjschr}</div>}
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            上传会议截图：
                        </span>
                        <Upload
                            listType="picture-card"
                            fileList={fileList1}
                            onPreview={handlePreview}
                            onChange={handleChange1}
                            customRequest={customRequest}
                            accept=".jpg,.png,.jpeg,.svg,.gif"
                            disabled ={tjStatus == 1}
                        >
                            {fileList1.length >= 4||tjStatus==1 ? null : uploadButton}
                        </Upload>
                        {tjStatus == 0?<div className={styles.uploadImgDes}>申报评审在线会议的截图或照片均可</div>:null}
                        <Modal visible={previewVisible} footer={null}
                               onCancel={handleCancel}
                               width={1200}
                        >
                            <img alt="example" style={{width: '100%'}} src={previewImage}/>
                        </Modal>
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            上传申请表：
                        </span>
                        <Upload
                            listType="picture-card"
                            fileList={fileList2}
                            onPreview={handlePreview}
                            onChange={handleChange2}
                            customRequest={customRequest}
                            disabled ={tjStatus == 1}
                        >
                            {fileList2.length >= 6 ||tjStatus==1? null : uploadButton}
                        </Upload>
                        {tjStatus == 0?<div className={styles.uploadImgDes}>上传学校已盖章的诺奖计划申请表图片/照片</div>:null}
                    </div>
                    <div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>
                            上传申报材料：
                        </span>
                        <Upload
                            listType="picture-card"
                            fileList={fileList3}
                            // onPreview={handlePreviewWork}
                            onPreview={handlePreview}
                            onChange={handleChange3}
                            customRequest={customRequest}
                            disabled ={tjStatus == 1}
                        >
                            {fileList3.length >= 8 ||tjStatus==1? null : uploadButton}
                        </Upload>
                        {tjStatus == 0?<div className={styles.uploadImgDes}>上传学校参会时的宣讲PPT/文档等材料</div>:null}
                    </div>
                    <div className={styles.bottomLine1}>
                        <span className={styles.bottomLeftTitle}>
                            评审结论：
                        </span>
                        {tjStatus==0?<select className={styles.schoolList}
                                onChange={(e) => {
                                    dispatch(setpsjlIndex(e.target.value))
                                }}
                                value={psjlIndex}
                        >
                            {psjlList.map((item, index) => {
                                return <option value={index}>{item.name}</option>
                            })}
                        </select>:
                            <span className={styles.rightContent1}>{psjlList[psjlIndex].name}</span>}
                    </div>
                    {psjlIndex == 1?<div className={styles.bottomLine2}>
                         <span className={styles.bottomLeftTitle}>

                        </span>
                        <Upload
                            listType="picture-card"
                            fileList={fileList4}
                            onPreview={handlePreview}
                            onChange={handleChange4}
                            customRequest={customRequest}
                            accept=".jpg,.png,.jpeg,.svg,.gif"
                            disabled ={tjStatus == 1}
                        >
                            {fileList4.length >= 6 ||tjStatus==1? null : uploadButton}
                        </Upload>
                        {tjStatus == 0?<div className={styles.uploadImgDes}>上传已盖章的学校与诺奖计划的合作协议图片/照片</div>:null}
                    </div>:null}
                    <div className={styles.bottomLine3}>
                        <div className={classNames(styles.tijiaoButton,tjStatus == 1?styles.tijiaoButton1:null)}
                             onClick={(e) => {
                                 if(tjStatus == 1) {
                                     setTjStatus(0);
                                     return;
                                 }
                                 let haveError1 = false;
                                 let fileName1 = '';
                                 fileList1.map((item, index) => {
                                     if (item.status == "error") {
                                         haveError1 = true;
                                     }
                                     if (fileName1 == "") {
                                         fileName1 = item.url
                                     } else {
                                         fileName1 = fileName1 + "," + item.url
                                     }
                                 })
                                 let haveError2 = false;
                                 let fileName2 = '';
                                 fileList2.map((item, index) => {
                                     if (item.status == "error") {
                                         haveError2 = true;
                                     }
                                     if (fileName2 == "") {
                                         fileName2 = item.url
                                     } else {
                                         fileName2 = fileName2 + "," + item.url
                                     }
                                 })
                                 let haveError3 = false;
                                 let fileName3 = '';
                                 fileList3.map((item, index) => {
                                     if (item.status == "error") {
                                         haveError3 = true;
                                     }
                                     if (fileName3 == "") {
                                         fileName3 = item.url
                                     } else {
                                         fileName3 = fileName3 + "," + item.url
                                     }
                                 })
                                 let haveError4 = false;
                                 let fileName4 = '';
                                 fileList4.map((item, index) => {
                                     if (item.status == "error") {
                                         haveError4 = true;
                                     }
                                     if (fileName4 == "") {
                                         fileName4 = item.url
                                     } else {
                                         fileName4 = fileName4 + "," + item.url
                                     }
                                 })
                                 if (SSMSchoolList.length == 0) {
                                     message.open({
                                         type: "warning",
                                         content: "需先完成甄选触达环节"
                                     })
                                     return;
                                 } else if (rangeTime[1] == "") {
                                     message.open({
                                         type: "warning",
                                         content: "请选择会议时间"
                                     })
                                     return;
                                 } else if (xxchr == "") {
                                     message.open({
                                         type: "warning",
                                         content: "请填写学校参会人"
                                     })
                                     return;
                                 } else if (yjschr == "") {
                                     message.open({
                                         type: "warning",
                                         content: "请填写研究所参会人"
                                     })
                                     return;
                                 } else if (haveError1||haveError2||haveError3||haveError4) {
                                     message.open({
                                         type: "warning",
                                         content: "部分图片或文档上传失败，请将红色边框图片删除后重新上传"
                                     })
                                     return;
                                 } else if (fileList1.length == 0) {
                                     message.open({
                                         type: "warning",
                                         content: "请先上传会议截图"
                                     })
                                     return;
                                 } else if (fileList2.length === 0) {
                                     message.open({
                                         type: "warning",
                                         content: "请先上传申请表图片"
                                     })
                                     return;
                                 }else if (fileList3.length === 0) {
                                     message.open({
                                         type: "warning",
                                         content: "请先上传申报材料"
                                     })
                                     return;
                                 }else if (fileList4.length === 0&&psjlIndex==1) {
                                     message.open({
                                         type: "warning",
                                         content: "请先上传协议图片"
                                     })
                                     return;
                                 } else if (psjlIndex == 0) {
                                     message.open({
                                         type: "warning",
                                         content: "评审结论未选择"
                                     })
                                     return;
                                 }
                                 addSBPSSchoolInfoRequest(
                                     SBPSXueqiList[SBPSXueqiIndex].xueqi_code,
                                     SBPSXueqiList[SBPSXueqiIndex].xueqi_nmae,
                                     SSMSchoolList[XSSMSchoolIndex].school_code,
                                     SSMSchoolList[XSSMSchoolIndex].school_name,
                                     rangeTime[0],
                                     rangeTime[1],
                                     xxchr,
                                     yjschr,
                                     fileName1,
                                     psjlList[psjlIndex].code,
                                     psjlList[psjlIndex].name,
                                     fileName2,
                                     fileName3,
                                     fileName4,
                                     (data) => {
                                         if (data.ret == 1) {
                                             setTjStatus(1)
                                             messageApi.open({
                                                 type: 'success',
                                                 content: "恭喜您，提交完成"
                                             })
                                             dispatch(setXMLDMenuIndex(2))
                                         }
                                     })
                             }}
                        >
                            {tjStatus == 1 ? "修改" : "提交"}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
};

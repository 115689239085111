import request from "../../../util/request";

export function getSchoolListRequest(xuezhiCode, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "zhenxuan_list");
    filedata.append('username_login', sessionStorage.getItem('username_login'));
    filedata.append('xuezhi_code', xuezhiCode);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}

export function saveLieSchoolRequest(xuezhiCode,schoolsJson, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "zhenxuan_lie");
    filedata.append('username_login', sessionStorage.getItem('username_login'));
    filedata.append('xuezhi_code', xuezhiCode);
    filedata.append('xuexiao_json', schoolsJson);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}

export function deleteLieSchoolRequest(xuezhiCode,xuexiao_code, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "zhenxuan_yichu");
    filedata.append('username_login', sessionStorage.getItem('username_login'));
    filedata.append('xuezhi_code', xuezhiCode);
    filedata.append('xuexiao_code', xuexiao_code);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}
export function savePingSchoolRequest(xuezhiCode,schoolsJson, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "zhenxuan_ping");
    filedata.append('username_login', sessionStorage.getItem('username_login'));
    filedata.append('xuezhi_code', xuezhiCode);
    filedata.append('xuexiao_json', schoolsJson);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}
export function saveDingSchoolRequest(xuezhiCode,schoolsJson, callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "zhenxuan_ding");
    filedata.append('username_login', sessionStorage.getItem('username_login'));
    filedata.append('xuezhi_code', xuezhiCode);
    filedata.append('xuexiao_json', schoolsJson);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}

export function addSchoolHX(xuexiao_code, data_json,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "xuexiaohuaxiang_add");
    filedata.append('username_login', sessionStorage.getItem('username_login'));
    filedata.append('xuexiao_code', xuexiao_code);
    filedata.append('data_json', data_json);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}
export function getSchoolHX(xuexiao_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "xuexiaohuaxiang");
    filedata.append('xuexiao_code', xuexiao_code);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}

export function addSchoolDYFX(xuexiao_code, data_json,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "diaoyanfenxi_add");
    filedata.append('username_login', sessionStorage.getItem('username_login'));
    filedata.append('xuexiao_code', xuexiao_code);
    filedata.append('data_json', data_json);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}
export function getSchoolDYFX(xuexiao_code,callback) {
    let filedata = new FormData();
    filedata.append('appkey', sessionStorage.getItem("utoken"));
    filedata.append('caozuo', "diaoyanfenxi");
    filedata.append('xuexiao_code', xuexiao_code);
    return request("/index.php/Yunyingd/i", {
        method: "POST",
        body: filedata
    }, callback);
}
import React, {useEffect} from "react";
import './App.css';
import {
    selectLogin,
    selectZXYYMain,
    selectCWZGMain,
    selectZBZGMain,
    selectYYZJMain,
    selectCPZJLMain,
    selectQGZMain,
    selectQJSJYYMain,
    closeLogin,
    openCWZGMain,
    openZXYYMain,
    openQJSJYYMain,
    openZBZGMain
} from './store/modalsSlice';
import {useSelector, useDispatch} from 'react-redux';
import {Login} from "./features/Login/Login"
import {ZXYYMain} from './features/Zhuxiaoyunying/ZXYYMain/ZXYYMain';
import {CWZGMain} from "./features/caiwuzhuguan/CWZGMain/CWZGMain";
import {ZBZGMain} from "./features/ZBZhuGuan/ZBZGMain/ZBZGMain";
import {CustomAlert} from "./features/CustomView/CustomAlert";
import {selectAlertInfo, setAlertInfo} from "./store/CustomViewSlice";
import {YYZJMain} from "./features/YunYingZongJian/YYZJMain/YYZJMain";
import {CPZJLMain} from "./features/CPZJL/CPZJLMain/CPZJLMain";
import {QGZMain} from "./features/QGZ/QGZMain/QGZMain";
import {QJSJYYMain} from "./features/QJSJYunYing/QJSJYYMain/QJSJYYMain";
import {autoLoginRequest} from "./features/Login/loginApi";
import {message} from "antd";

function App() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const loginVisible = useSelector(selectLogin);
    const ZXYYMainVisible = useSelector(selectZXYYMain)
    const CWZGMainVisible = useSelector(selectCWZGMain)
    const ZBZGMainVisible = useSelector(selectZBZGMain)
    const YYZJMainVisible = useSelector(selectYYZJMain)
    const CPZJLMainVisible = useSelector(selectCPZJLMain)
    const QGZMainVisible = useSelector(selectQGZMain)
    const QJSJYYMainVisible = useSelector(selectQJSJYYMain)
    const alertInfo = useSelector(selectAlertInfo)
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        const showAlert = (e) => {
            // 处理窗口大小变化的逻辑
            dispatch(setAlertInfo({
                showStatus: true,
                title: "",
                title1: e.detail.content,
                content: ""
            }))
        };
        autoLogin();
        window.addEventListener('showCustomAlert', showAlert);

        return () => {
            // 组件卸载前移除事件监听
            window.removeEventListener('showCustomAlert', showAlert);
        };
    }, []);
    function autoLogin() {
        const url = new URL(window.location.href);
        const utoken = url.searchParams.get('utoken'); // 返回 'value1'
        if(utoken != null){
            sessionStorage.setItem("utoken",utoken)
            var pathname = window.location.pathname;

// 获取当前URL的查询字符串（不包括'?'）
            var search = window.location.search;

// 如果当前URL有查询参数，则去掉它们
            if (search) {
                // 使用replaceState更改地址栏URL而不产生新的历史记录
               window.history.replaceState(null, null, pathname);
            }
// 重新加载页面
            window.location.reload();
            autoLoginRequest(utoken,(data)=>{
                if (data.ret == 1) {
                    let dictionary = data.data;

                    for (let key in dictionary) {
                        if (dictionary.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                            // if(key!="username"&&key!="utoken"&&key!="pwd"&&key!="username_login"){
                            if (key != "pwd") {
                                sessionStorage.setItem(key, dictionary[key])
                            }
                        }
                    }
                    dispatch(closeLogin())
                    if (data.data.user_type == 3) {
                        dispatch(openCWZGMain())
                        dispatch(closeLogin())
                    } else if (data.data.user_type == 4) {
                        dispatch(openZXYYMain())
                        dispatch(closeLogin())
                    }else if (data.data.user_type == 7) {
                        dispatch(openQJSJYYMain())
                        dispatch(closeLogin())
                    } else if (data.data.user_type == 99) {
                        dispatch(openZBZGMain())
                        dispatch(closeLogin())
                    }

                } else if(data.ret==99){
                    messageApi.open({
                        type: 'warning',
                        // content: data.msg,
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
            })
        }
    }
    return (
        <div className="App">
            {contextHolder}
            <div className="pageContainer">
                {loginVisible ? <Login/> : null}
                {ZXYYMainVisible ? <ZXYYMain/> : null}
                {CWZGMainVisible ? <CWZGMain/> : null}
                {ZBZGMainVisible ? <ZBZGMain/> : null}
                {YYZJMainVisible ? <YYZJMain/> : null}
                {CPZJLMainVisible ? <CPZJLMain/> : null}
                {QGZMainVisible ? <QGZMain/> : null}
                {QJSJYYMainVisible?<QJSJYYMain/>:null}

                <CustomAlert
                    info={alertInfo}
                />
            </div>

        </div>

    );
}

export default App;

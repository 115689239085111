// 提交开课申请,暂未对接接口
import React, {useRef, useState, useEffect} from "react";
import styles from "./SBPSHJDetail.module.css";
import classNames from "classnames"
import {useSelector, useDispatch} from 'react-redux';
import Footer from "../../Footer/Footer";
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {selectAlertInfo, setAlertInfo} from "../../../store/CustomViewSlice";
import {message, Image} from 'antd'
import {
    selectSBPSDetailInfo,
} from "./SBPSHJSlice";
import {API_URL} from "../../../util/constants";
import OSS from 'ali-oss'
import {
    getAliOssTokenRequest,
} from "./SBPSHJApi";
import {getSixMathRandom} from "../../../util/handMath";
import {setSBPSMenuIndex, setXSSMMenuIndex} from "../ZXYYMain/ZXYYMainSlice";

let client;


export function SBPSHJDetail() {
    const dispatch = useDispatch();
    const [messageApi, contextHolder] = message.useMessage();
    const [confirmInfo, setConfirmInfo] = useState({})
    const schoolInfo = useSelector(selectSBPSDetailInfo);
    useEffect(() => {
        // 在这里执行你的组件挂载后的逻辑
        // 这里可以返回一个清理函数，它会在组件卸载前执行
        getAliOssToken()
        return () => {
            // console.log('组件即将卸载');
        };
    }, []); // 空数组意味着这个 effect 只会在组件挂载时执行一次

    function getAliOssToken() {
        if (!client) {
            getAliOssTokenRequest((data) => {
                if ("Credentials" in data) {
                    client = new OSS({
                        // yourRegion填写Bucket所在地域。以华东1（杭州）为例，Region填写为oss-cn-hangzhou。
                        region: 'oss-cn-qingdao',
                        // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                        accessKeyId: data.Credentials.AccessKeyId,
                        accessKeySecret: data.Credentials.AccessKeySecret,
                        // 从STS服务获取的安全令牌（SecurityToken）。
                        stsToken: data.Credentials.SecurityToken,
                        refreshSTSToken: async () => {
                            // 向您搭建的STS服务获取临时访问凭证。
                            getAliOssTokenRequest((data) => {
                                return {
                                    accessKeyId: data.Credentials.AccessKeyId,
                                    accessKeySecret: data.Credentials.AccessKeySecret,
                                    stsToken: data.Credentials.SecurityToken,
                                }
                            })

                        },
                        // 刷新临时访问凭证的时间间隔，单位为毫秒。
                        refreshSTSTokenInterval: 3000000,
                        // 填写Bucket名称。
                        bucket: 'tgnp'
                    })
                }
            })
        }
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
        } else if (confirmInfo.eventId == 2) {

        }

    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }



    return (
        <div className={styles.background}>
            {contextHolder}
            <CustomConfirmAlert
                info={confirmInfo}
                cancelMethod={cancelMethod}
                confirmMethod={confirmMethod}
            />
            <div className={styles.topBar}>
                <div className={styles.backContainer}
                     onClick={(e)=>{
                         dispatch(setSBPSMenuIndex(0))
                     }}
                >
                    <span className={styles.backIcon}>&lt;</span>
                    <span>&nbsp;返回</span>
                </div>
            </div>
            <div className={styles.content}>
                <span className={styles.schoolName}>{schoolInfo.school_name}</span>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>会议日期：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.start_time}&nbsp;~&nbsp;{schoolInfo.end_time}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>学校参会人：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.school_canhui}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>研究所参会人：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.yanjiusuo_canhui}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>会议截图：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.huiyi_image.split(",").map((item, index) => {
                            return <div className={styles.imgCard}>
                                <Image src={item} alt="图片获取失败"></Image>
                            </div>
                        })}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>上传申请表：</span>
                    <span className={styles.rightContent2}>
                        {schoolInfo.shenqingbiao_image.split(",").map((item, index) => {
                            return <a href={item}>{decodeURI(item.split("shenbao/")[1]).slice(-100)}</a>
                            // return <div className={styles.imgCard}>
                            //     <Image src={item} alt="图片获取失败"></Image>
                            // </div>
                        })}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>上传申报材料：</span>
                    <span className={styles.rightContent2}>
                        {schoolInfo.cailiao__url.split(",").map((item, index) => {
                            // return <a href={item}></a>
                            return <a href={item}>{decodeURI(item.split("shenbao/")[1]).slice(-100)}</a>
                        })}
                    </span>
                </div>
                <div className={styles.contentLine}>
                    <span className={styles.leftTitle}>评审结论：</span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.jielun_name}
                    </span>
                </div>
                <div className={styles.contentLine1}>
                    <span className={styles.leftTitle}></span>
                    <span className={styles.rightContent1}>
                        {schoolInfo.xieyi_image.split(",").map((item, index) => {
                            return <div className={styles.imgCard}>
                                <Image src={item} alt="图片获取失败"></Image>
                            </div>
                        })}
                    </span>
                </div>

            </div>
            <Footer/>
        </div>
    );
};

import React, {useRef, useState, useEffect} from "react";
import styles from "./InfoItem.module.css";
import classNames from "classnames"
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faL, faMinusCircle} from '@fortawesome/free-solid-svg-icons';
import {
    selectInfoList,
    setInfoCheckedAll,
    setInfoList, setPaymentInfo,
    setQRContentStr,
    setQRDownloadStatus, setRefundRecordList, setShowRefundAlert, setShowRefundRecord
} from "./PaymentQuerySlice";
import {useDispatch, useSelector} from "react-redux";
import {API_URL} from "../../../util/constants";
import {getRefoundRecord, shenhePayment} from "./PaymentQueryApi";
import {Checkbox} from 'antd';
import {CustomConfirmAlert} from "../../CustomView/CustomConfirmAlert";
import {setAlertInfo} from "../../../store/CustomViewSlice";
import {message} from 'antd'
import {getMoneyDate} from "../../../util/currencyUnit";
// const PaymentInfoList = props =>(
export default function InfoItem(props) {
    const dispatch = useDispatch();
    const infoList = useSelector(selectInfoList);
    const [confirmInfo, setConfirmInfo] = useState({})
    const [messageApi, contextHolder] = message.useMessage();

    function acceptMethod() {
        console.log(props.item)

        setConfirmInfo({
            showStatus: true,
            title: "",
            title1: `是否通过${props.item.name}的申请？`,
            content: "",
            eventId: 1
        })
    }

    function cancelMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
    }

    function confirmMethod(params) {
        setConfirmInfo({
            showStatus: false,
            title: "",
            title1: "",
            content: ""
        })
        if (confirmInfo.eventId == 1) {
            shenhePayment(JSON.stringify([{baoming_id: props.item.Id}]), "1", (data) => {
                if (data.ret == 1) {
                    let tempInfoList = []
                    infoList.forEach(element => {
                        var newElement = {}
                        for (let key in element) {
                            if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                newElement[key] = element[key]
                            }
                        }
                        if (newElement.Id == props.item.Id) {
                            newElement.shenhe_status = "1"
                        }
                        tempInfoList.push(newElement)
                    });
                    dispatch(setInfoList(tempInfoList))
                }else if(data.ret==99){
                    messageApi.open({
                        type: 'warning',
                        // content: data.msg,
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
            })
        } else if (confirmInfo.eventId == 2) {
            shenhePayment(JSON.stringify([{baoming_id: props.item.Id}]), "2", (data) => {
                if (data.ret == 1) {
                    let tempInfoList = []
                    infoList.forEach(element => {
                        var newElement = {}
                        for (let key in element) {
                            if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                                newElement[key] = element[key]
                            }
                        }
                        if (newElement.Id == props.item.Id) {
                            newElement.shenhe_status = "2"
                        }
                        tempInfoList.push(newElement)
                    });
                    dispatch(setInfoList(tempInfoList))
                }else if(data.ret==99){
                    messageApi.open({
                        type: 'warning',
                        // content: data.msg,
                        content:data.msg
                    });
                    setTimeout((e)=>{
                        sessionStorage.clear()
                        window.location.reload()
                    },1000)
                }
            })

        }
    }

    //开课到款暂时没有拒绝审核的功能
    function passMethod() {
        setConfirmInfo({
            showStatus: true,
            title: "",
            title1: `是否拒绝${props.item.name}的申请？`,
            content: "",
            eventId: 2
        })
    }

    function onChange(e) {
        let tempInfoList = []
        let checkedAll = true;
        infoList.forEach(element => {
            var newElement = {}
            for (let key in element) {
                if (element.hasOwnProperty(key)) { // 确保是对象自有属性，不是原型链上的属性
                    newElement[key] = element[key]
                }
            }
            if (newElement.Id == props.item.Id) {
                if (e.target.checked) {
                    newElement.checked = true
                } else {
                    newElement.checked = false
                }
            }
            tempInfoList.push(newElement)
            if (newElement.checked == false) {
                checkedAll = false
            }
        });
        console.log(tempInfoList)
        dispatch(setInfoList(tempInfoList))
        dispatch(setInfoCheckedAll(checkedAll))
    }

    return <tr
        className={styles.tr1}
    >
        <td
            className={styles.xuhaoTd}
        >
            {props.index}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.jiaofei_time}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.shoufei_zhouqi_code}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.shoufei_zhouqi_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.school_shengji_name}
        </td>
        {/*<td*/}
        {/*    className={styles.td1}*/}
        {/*>*/}
        {/*    {props.item.school_shiji_name}*/}
        {/*</td>*/}
        <td
            className={styles.td1}
        >
            {props.item.school_quxian_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.school_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.grade_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.class_name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.name}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.xingbie == 1 ? '男' : '女'}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.shengri}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.kecheng_name}
        </td>
        <td
            className={styles.td1}
        >
            {getMoneyDate("yuan",props.item.kecheng_jiage)}

        </td>
        <td
            className={styles.td1}
        >
            {props.item.jiaoju_name}
        </td>
        <td
            className={styles.td1}
        >
            {getMoneyDate("yuan",props.item.jiaoju_jiage)}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.pay_type == 1 ? '微信' : props.item.pay_type == 2 ? "支付宝" : '第三方'}
        </td>
        <td
            className={styles.td1}
        >
            {props.item.jiazhang_name}
        </td>
        <td
            className={classNames(styles.noSelect, styles.td1)}
        >
            *******{props.item.jiazhang_phone.substring(7)}
        </td>
        <td
            className={styles.td2}
        >
            {props.item.tuifei_status == 1 ?
                <span className={classNames(styles.listContentSpan, styles.manageDes)}
                      onClick={(e) => {
                          getRefoundRecord(props.item.Id, (data) => {
                              if (data.ret == 1) {
                                  dispatch(setRefundRecordList(data.data))
                                  dispatch(setShowRefundRecord(true))
                              }else if(data.ret==99){
                                messageApi.open({
                                    type: 'warning',
                                    // content: data.msg,
                                    content:data.msg
                                });
                                setTimeout((e)=>{
                                    sessionStorage.clear()
                                    window.location.reload()
                                },1000)
                            } else {
                                  dispatch(setAlertInfo({
                                      showStatus: true,
                                      title: "",
                                      title1: "获取退款记录失败",
                                      content: ""
                                  }))
                              }
                          })
                          // dispatch(setShowRefundRecord(true))
                      }}
                >退费记录</span> : null}
        </td>
        <td className={styles.td2}>
            <span
                className={classNames(styles.listContentSpan, styles.manageDes)}
                onClick={(e) => {
                    dispatch(setPaymentInfo(props.item))
                    dispatch(setShowRefundAlert(true))
                }}
            >退费</span>
        </td>
        <CustomConfirmAlert
            info={confirmInfo}
            cancelMethod={cancelMethod}
            confirmMethod={confirmMethod}
        />
        {contextHolder}
    </tr>
}
// export default PaymentInfoList;

